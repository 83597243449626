// Generated by Framer (be6943a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kwWoDV5Ia", "Yg1xKB1ZZ", "eZFqLPORZ", "RcfaASuFu", "SMfsCkxD3", "TuHg_5uhi", "cuFnDABuE", "jnclnB6U3", "dnodGnZCD"];

const serializationHash = "framer-9MpVR"

const variantClassNames = {cuFnDABuE: "framer-v-1snjsol", dnodGnZCD: "framer-v-lnt6gr", eZFqLPORZ: "framer-v-14w80j5", jnclnB6U3: "framer-v-eyoc26", kwWoDV5Ia: "framer-v-1xjwxzq", RcfaASuFu: "framer-v-1umcxi0", SMfsCkxD3: "framer-v-13dlc3l", TuHg_5uhi: "framer-v-12j7989", Yg1xKB1ZZ: "framer-v-16slom7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "TuHg_5uhi", "Image 16:9": "RcfaASuFu", "Image 3:2": "eZFqLPORZ", "Image 4:3": "Yg1xKB1ZZ", "Image 4:5": "SMfsCkxD3", "Variant 7": "cuFnDABuE", "Variant 8": "jnclnB6U3", "Variant 9": "dnodGnZCD", Default: "kwWoDV5Ia"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kwWoDV5Ia", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kwWoDV5Ia", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({RcfaASuFu: {value: transition2}}, baseVariant, gestureVariant)}><Image {...restProps} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1xjwxzq", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"kwWoDV5Ia"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({cuFnDABuE: {"data-framer-name": "Variant 7"}, dnodGnZCD: {"data-framer-name": "Variant 9"}, eZFqLPORZ: {"data-framer-name": "Image 3:2"}, jnclnB6U3: {"data-framer-name": "Variant 8"}, RcfaASuFu: {"data-framer-name": "Image 16:9"}, SMfsCkxD3: {"data-framer-name": "Image 4:5"}, TuHg_5uhi: {"data-framer-name": "Image 1:1"}, Yg1xKB1ZZ: {"data-framer-name": "Image 4:3"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9MpVR.framer-spiahd, .framer-9MpVR .framer-spiahd { display: block; }", ".framer-9MpVR.framer-1xjwxzq { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-9MpVR.framer-v-16slom7.framer-1xjwxzq { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-9MpVR.framer-v-14w80j5.framer-1xjwxzq { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-9MpVR.framer-v-1umcxi0.framer-1xjwxzq { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-9MpVR.framer-v-13dlc3l.framer-1xjwxzq { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-9MpVR.framer-v-12j7989.framer-1xjwxzq { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-9MpVR.framer-v-1snjsol.framer-1xjwxzq { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-9MpVR.framer-v-eyoc26.framer-1xjwxzq { aspect-ratio: 0.46545454545454545 / 1; height: var(--framer-aspect-ratio-supported, 550px); }", ".framer-9MpVR.framer-v-lnt6gr.framer-1xjwxzq { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Yg1xKB1ZZ":{"layout":["fixed","fixed"]},"eZFqLPORZ":{"layout":["fixed","fixed"]},"RcfaASuFu":{"layout":["fixed","fixed"]},"SMfsCkxD3":{"layout":["fixed","fixed"]},"TuHg_5uhi":{"layout":["fixed","fixed"]},"cuFnDABuE":{"layout":["fixed","fixed"]},"jnclnB6U3":{"layout":["fixed","fixed"]},"dnodGnZCD":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framercb1m844w_: React.ComponentType<Props> = withCSS(Component, css, "framer-9MpVR") as typeof Component;
export default Framercb1m844w_;

Framercb1m844w_.displayName = "Image / Aspect Ratio Copy 9";

Framercb1m844w_.defaultProps = {height: 256, width: 256};

addPropertyControls(Framercb1m844w_, {variant: {options: ["kwWoDV5Ia", "Yg1xKB1ZZ", "eZFqLPORZ", "RcfaASuFu", "SMfsCkxD3", "TuHg_5uhi", "cuFnDABuE", "jnclnB6U3", "dnodGnZCD"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(Framercb1m844w_, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})